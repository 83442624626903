function AppStore() {
  return (
    <svg width="122" height="41" viewBox="0 0 122 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="livetype" clipPath="url(#clip0_140_1125)">
        <g id="Group">
          <g id="Group_2">
            <path id="Vector" d="M112.284 0H9.71636C9.34932 0 8.98228 0 8.60505 0C8.28898 0 7.98312 0 7.66706 0.01025C6.98395 0.01025 6.30085 0.082 5.62795 0.19475C4.95504 0.3075 4.30252 0.52275 3.69079 0.8405C3.07906 1.15825 2.5285 1.558 2.03911 2.05C1.54972 2.53175 1.1419 3.0955 0.836035 3.7105C0.530169 4.3255 0.316062 4.9815 0.203911 5.658C0.0815644 6.3345 0.0203911 7.02125 0.0203911 7.708C0.0203911 8.02575 0.0101956 8.33325 0 8.651V32.3387C0 32.6565 0 32.964 0.0203911 33.2817C0.0203911 33.9685 0.09176 34.6553 0.203911 35.3317C0.316062 36.0083 0.530169 36.6745 0.846231 37.2792C1.1521 37.8942 1.55992 38.4478 2.04931 38.9295C2.5285 39.4215 3.08925 39.8212 3.70099 40.139C4.31272 40.4465 4.96523 40.672 5.63814 40.7848C6.31105 40.8975 6.99415 40.959 7.67725 40.9692C7.99331 40.9692 8.29918 40.9795 8.61524 40.9795C8.99248 40.9795 9.35952 40.9795 9.72656 40.9795H112.294C112.661 40.9795 113.028 40.9795 113.395 40.9795C113.701 40.9795 114.027 40.9795 114.333 40.9692C115.016 40.9692 115.699 40.8975 116.372 40.7848C117.045 40.672 117.708 40.4465 118.319 40.139C118.931 39.8315 119.482 39.4215 119.971 38.9295C120.46 38.4478 120.858 37.884 121.174 37.2792C121.48 36.6642 121.694 36.0083 121.806 35.3317C121.918 34.6553 121.98 33.9685 122 33.2817C122 32.964 122 32.6565 122 32.3387C122 31.9697 122 31.6007 122 31.2215V9.7785C122 9.39925 122 9.03025 122 8.66125C122 8.3435 122 8.036 122 7.71825C121.99 7.0315 121.929 6.34475 121.806 5.66825C121.694 4.99175 121.48 4.33575 121.174 3.72075C120.542 2.49075 119.543 1.48625 118.319 0.85075C117.708 0.54325 117.055 0.328 116.372 0.205C115.699 0.09225 115.016 0.03075 114.333 0.0205C114.027 0.0205 113.701 0.01025 113.395 0.01025C113.028 0.01025 112.661 0.01025 112.294 0.01025L112.284 0Z" fill="#3C3E41" />
            <path id="Vector_2" d="M8.605 40.1082C8.29913 40.1082 7.99326 40.1082 7.6874 40.098C7.04508 40.098 6.41295 40.0365 5.78083 39.934C5.18949 39.8315 4.62873 39.647 4.08837 39.3702C3.5582 39.1037 3.07901 38.745 2.66099 38.3247C2.24297 37.9045 1.88613 37.4227 1.62104 36.8897C1.35596 36.3567 1.16224 35.7827 1.07048 35.1882C0.968527 34.5527 0.907354 33.907 0.897158 33.2612C0.897158 33.046 0.886963 32.3285 0.886963 32.3285V8.651C0.886963 8.651 0.886963 7.94375 0.897158 7.73875C0.897158 7.093 0.958332 6.4575 1.07048 5.822C1.17244 5.2275 1.35596 4.6535 1.62104 4.1205C1.88613 3.5875 2.23278 3.10575 2.66099 2.6855C3.07901 2.26525 3.5582 1.9065 4.08837 1.64C4.61854 1.3735 5.18949 1.17875 5.77063 1.0865C6.40276 0.983997 7.04508 0.922497 7.6874 0.922497H8.605L113.385 0.912247H114.312C114.945 0.932747 115.577 0.983997 116.209 1.09675C116.8 1.19925 117.371 1.38375 117.912 1.6605C118.972 2.20375 119.828 3.075 120.379 4.141C120.644 4.674 120.827 5.248 120.929 5.83225C121.031 6.46775 121.093 7.1135 121.103 7.7695C121.103 8.0565 121.103 8.37425 121.103 8.68175C121.103 9.07125 121.103 9.43 121.103 9.799V31.2522C121.103 31.6212 121.103 31.9902 121.103 32.3592C121.103 32.6975 121.103 32.9947 121.103 33.3125C121.093 33.948 121.031 34.5835 120.929 35.2087C120.838 35.8032 120.644 36.3772 120.379 36.9205C120.114 37.4432 119.757 37.925 119.339 38.3452C118.921 38.7655 118.442 39.1242 117.912 39.3907C117.371 39.6675 116.8 39.852 116.209 39.9545C115.577 40.057 114.945 40.1185 114.302 40.1185C114.007 40.1185 113.691 40.1287 113.385 40.1287H112.284H8.605V40.1082Z" fill="#0A0C19" />
          </g>
          <g id="Group_3">
            <g id="Group 2">
              <g id="Group 3">
                <path id="Path" d="M25.2543 20.8075C25.2747 19.065 26.1821 17.466 27.6605 16.5538C26.7225 15.211 25.2135 14.391 23.5925 14.3398C21.8796 14.1553 20.2177 15.3648 19.3511 15.3648C18.4845 15.3648 17.1183 14.35 15.6705 14.3808C13.7639 14.4423 12.0409 15.5083 11.1131 17.179C9.14535 20.6128 10.6135 25.6558 12.4997 28.4335C13.4479 29.7968 14.549 31.3138 15.9968 31.2523C17.4139 31.1908 17.9441 30.3503 19.6468 30.3503C21.3494 30.3503 21.8286 31.2523 23.307 31.2215C24.8261 31.201 25.7845 29.8583 26.6919 28.4848C27.375 27.5213 27.895 26.445 28.2416 25.3175C26.437 24.5488 25.2645 22.7755 25.2645 20.8075H25.2543Z" fill="white" />
                <path id="Path 2" d="M22.471 12.5153C23.2968 11.521 23.7047 10.2295 23.6027 8.938C22.3384 9.07125 21.166 9.676 20.3299 10.6395C19.5041 11.5825 19.0861 12.8228 19.1676 14.0835C20.4523 14.0938 21.6655 13.5198 22.471 12.5153Z" fill="white" />
              </g>
            </g>
            <g id="Group_4">
              <path id="Vector_3" d="M43.1272 27.8185H38.3047L37.1424 31.2625H35.1033L39.6709 18.532H41.7916L46.3592 31.2625H44.2793L43.117 27.8185H43.1272ZM38.8043 26.2298H42.6276L40.7414 20.6435H40.6904L38.8043 26.2298Z" fill="white" />
              <path id="Vector_4" d="M56.2387 26.6193C56.2387 29.4995 54.6991 31.3548 52.3848 31.3548C51.1919 31.4163 50.0602 30.791 49.479 29.7353H49.4382V34.3273H47.5419V21.976H49.3771V23.5238H49.4077C50.0092 22.4783 51.1409 21.8428 52.344 21.8838C54.689 21.8838 56.2285 23.7493 56.2285 26.6193H56.2387ZM54.2913 26.6193C54.2913 24.7435 53.3227 23.5033 51.8546 23.5033C50.3864 23.5033 49.428 24.764 49.428 26.6193C49.428 28.4745 50.4068 29.7455 51.8546 29.7455C53.3024 29.7455 54.2913 28.5155 54.2913 26.6193Z" fill="white" />
              <path id="Vector_5" d="M66.3935 26.6193C66.3935 29.4995 64.8539 31.3548 62.5395 31.3548C61.3467 31.4163 60.215 30.791 59.6338 29.7353H59.593V34.3273H57.6967V21.976H59.5319V23.5238H59.5624C60.164 22.4783 61.2957 21.8428 62.4988 21.8838C64.8437 21.8838 66.3833 23.7493 66.3833 26.6193H66.3935ZM64.4461 26.6193C64.4461 24.7435 63.4775 23.5033 62.0094 23.5033C60.5412 23.5033 59.5828 24.764 59.5828 26.6193C59.5828 28.4745 60.5616 29.7455 62.0094 29.7455C63.4571 29.7455 64.4461 28.5155 64.4461 26.6193Z" fill="white" />
              <path id="Vector_6" d="M73.1123 27.716C73.2551 28.9768 74.4683 29.807 76.1404 29.807C77.8125 29.807 78.883 28.9768 78.883 27.839C78.883 26.855 78.1897 26.2605 76.5482 25.8505L74.9067 25.4508C72.5822 24.887 71.5014 23.7903 71.5014 22.017C71.5014 19.8235 73.408 18.3168 76.1098 18.3168C78.8116 18.3168 80.6163 19.8235 80.6774 22.017H78.7607C78.6485 20.746 77.5984 19.9773 76.0792 19.9773C74.5601 19.9773 73.51 20.7563 73.51 21.8838C73.51 22.7858 74.1727 23.3085 75.804 23.7185L77.2007 24.067C79.8006 24.682 80.8813 25.7378 80.8813 27.593C80.8813 29.971 78.9952 31.4675 75.9977 31.4675C73.1939 31.4675 71.2975 30.012 71.1752 27.7058H73.1123V27.716Z" fill="white" />
              <path id="Vector_7" d="M84.9799 19.7825V21.976H86.7336V23.4828H84.9799V28.5975C84.9799 29.397 85.3266 29.766 86.1014 29.766C86.3053 29.766 86.5194 29.7455 86.7234 29.725V31.2215C86.3767 31.283 86.0199 31.3138 85.6732 31.3138C83.8074 31.3138 83.0734 30.6065 83.0734 28.8128V23.493H81.7275V21.9863H83.0734V19.7928H84.9799V19.7825Z" fill="white" />
              <path id="Vector_8" d="M87.7532 26.6193C87.7532 23.698 89.466 21.8633 92.1271 21.8633C94.7881 21.8633 96.501 23.698 96.501 26.6193C96.501 29.5405 94.8085 31.3753 92.1271 31.3753C89.4456 31.3753 87.7532 29.5508 87.7532 26.6193ZM94.574 26.6193C94.574 24.6205 93.6564 23.4315 92.1271 23.4315C90.5977 23.4315 89.6801 24.6205 89.6801 26.6193C89.6801 28.618 90.5875 29.807 92.1271 29.807C93.6666 29.807 94.574 28.6385 94.574 26.6193Z" fill="white" />
              <path id="Vector_9" d="M98.071 21.976H99.8757V23.5545H99.9164C100.171 22.5398 101.099 21.8428 102.139 21.8735C102.353 21.8735 102.577 21.894 102.792 21.9453V23.7288C102.516 23.6468 102.231 23.6058 101.945 23.616C100.895 23.575 99.998 24.395 99.9572 25.461C99.9572 25.5635 99.9572 25.6558 99.9674 25.7583V31.2625H98.071V21.9863V21.976Z" fill="white" />
              <path id="Vector_10" d="M111.519 28.536C111.264 30.217 109.633 31.3753 107.543 31.3753C104.861 31.3753 103.189 29.5713 103.189 26.6603C103.189 23.7493 104.861 21.8633 107.461 21.8633C110.061 21.8633 111.621 23.6263 111.621 26.445V27.101H105.106V27.2138C104.994 28.5463 105.962 29.7148 107.288 29.8378C107.39 29.8378 107.492 29.848 107.583 29.8378C108.501 29.9198 109.368 29.397 109.714 28.536H111.509H111.519ZM105.116 25.7583H109.735C109.806 24.5283 108.868 23.4725 107.645 23.411C107.583 23.411 107.533 23.411 107.471 23.411C106.177 23.411 105.126 24.4463 105.116 25.748C105.116 25.748 105.116 25.7583 105.116 25.7685V25.7583Z" fill="white" />
            </g>
          </g>
        </g>
        <g id="Group 4">
          <g id="Group_5">
            <path id="Vector_11" d="M38.5698 8.94825C40.0482 8.84575 41.343 9.963 41.4449 11.4493C41.4551 11.6235 41.4449 11.808 41.4347 11.9823C41.4347 13.94 40.3846 15.0573 38.5698 15.0573H36.3676V8.938H38.5698V8.94825ZM37.3157 14.1963H38.4576C39.5078 14.2578 40.4152 13.448 40.4764 12.3923C40.4764 12.259 40.4764 12.1258 40.456 11.9925C40.5987 10.9368 39.8646 9.963 38.8145 9.8195C38.6921 9.799 38.5698 9.799 38.4474 9.80925H37.3055V14.1963H37.3157Z" fill="white" />
            <path id="Vector_12" d="M42.4951 12.751C42.383 11.5518 43.2598 10.4858 44.4527 10.3628C45.6456 10.25 46.7059 11.1315 46.8282 12.3308C46.8384 12.4743 46.8384 12.6075 46.8282 12.751C46.9404 13.9503 46.0738 15.0265 44.8707 15.1393C43.6778 15.252 42.6073 14.3808 42.4951 13.1713C42.4849 13.0278 42.4849 12.8843 42.4951 12.751ZM45.8903 12.751C45.8903 11.7465 45.4417 11.1623 44.6566 11.1623C43.8715 11.1623 43.4229 11.7465 43.4229 12.751C43.4229 13.7555 43.8613 14.3398 44.6566 14.3398C45.4417 14.3398 45.8903 13.7555 45.8903 12.751Z" fill="white" />
            <path id="Vector_13" d="M52.5785 15.0675H51.6405L50.6923 11.6645H50.6209L49.6728 15.0675H48.745L47.4807 10.455H48.3983L49.2242 13.981H49.2955L50.2437 10.455H51.1103L52.0585 13.981H52.1299L52.9455 10.455H53.8529L52.5887 15.0675H52.5785Z" fill="white" />
            <path id="Vector_14" d="M54.9031 10.4447H55.7799V11.1827H55.8513C56.0858 10.6395 56.6363 10.3115 57.2175 10.3627C58.0433 10.3012 58.757 10.9162 58.8182 11.7465C58.8182 11.8592 58.8182 11.972 58.808 12.0745V15.0572H57.9006V12.3C57.9006 11.562 57.5845 11.193 56.9116 11.193C56.3305 11.1622 55.8411 11.6132 55.8105 12.1975C55.8105 12.2487 55.8105 12.3102 55.8105 12.3615V15.0572H54.9031V10.4447Z" fill="white" />
            <path id="Vector_15" d="M60.2455 8.651H61.1529V15.0675H60.2455V8.651Z" fill="white" />
            <path id="Vector_16" d="M62.4171 12.751C62.305 11.5518 63.1818 10.4858 64.3747 10.3628C65.5676 10.25 66.6279 11.1315 66.7502 12.3308C66.7604 12.4743 66.7604 12.6075 66.7502 12.751C66.8624 13.9503 65.9958 15.0265 64.7927 15.1393C63.5896 15.252 62.5293 14.3808 62.4171 13.1713C62.4069 13.0278 62.4069 12.8843 62.4171 12.751ZM65.8123 12.751C65.8123 11.7465 65.3636 11.1623 64.5786 11.1623C63.7935 11.1623 63.3449 11.7465 63.3449 12.751C63.3449 13.7555 63.7833 14.3398 64.5786 14.3398C65.3636 14.3398 65.8123 13.7555 65.8123 12.751Z" fill="white" />
            <path id="Vector_17" d="M67.6985 13.7555C67.6985 12.9252 68.3102 12.4435 69.4011 12.382L70.645 12.3102V11.9105C70.645 11.4185 70.3289 11.152 69.707 11.152C69.1972 11.152 68.8506 11.3365 68.7486 11.6645H67.8718C67.9636 10.8752 68.7078 10.3627 69.7478 10.3627C70.8999 10.3627 71.5524 10.9367 71.5524 11.9105V15.0675H70.6756V14.4217H70.6042C70.3085 14.8932 69.7784 15.17 69.2278 15.1495C68.4632 15.2315 67.7801 14.6677 67.7087 13.9092C67.7087 13.8682 67.7087 13.817 67.7087 13.7657L67.6985 13.7555ZM70.6552 13.366V12.9765L69.5337 13.0482C68.9016 13.0892 68.6161 13.3045 68.6161 13.7145C68.6161 14.1245 68.9729 14.3705 69.4623 14.3705C70.0537 14.432 70.5838 13.9912 70.645 13.3967C70.645 13.3865 70.645 13.3762 70.645 13.3557L70.6552 13.366Z" fill="white" />
            <path id="Vector_18" d="M72.7452 12.751C72.7452 11.2955 73.4895 10.373 74.6518 10.373C75.233 10.3423 75.7835 10.66 76.0588 11.1828H76.1302V8.651H77.0376V15.0675H76.1709V14.3398H76.0996C75.8039 14.8523 75.2534 15.17 74.662 15.1495C73.4895 15.1495 72.7554 14.227 72.7554 12.7613L72.7452 12.751ZM73.6832 12.751C73.6832 13.735 74.142 14.3193 74.9067 14.3193C75.6714 14.3193 76.1404 13.7248 76.1404 12.751C76.1404 11.7773 75.6612 11.1828 74.9067 11.1828C74.1522 11.1828 73.6832 11.7773 73.6832 12.751Z" fill="white" />
            <path id="Vector_19" d="M80.7794 12.751C80.6673 11.5518 81.5441 10.4858 82.737 10.3628C83.9299 10.2398 84.9902 11.1315 85.1126 12.3308C85.1227 12.4743 85.1227 12.6075 85.1126 12.751C85.2247 13.9503 84.3581 15.0265 83.155 15.1393C81.9621 15.252 80.8916 14.3808 80.7794 13.1713C80.7692 13.0278 80.7692 12.8843 80.7794 12.751ZM84.1746 12.751C84.1746 11.7465 83.726 11.1623 82.9409 11.1623C82.1558 11.1623 81.7072 11.7465 81.7072 12.751C81.7072 13.7555 82.1456 14.3398 82.9409 14.3398C83.726 14.3398 84.1746 13.7555 84.1746 12.751Z" fill="white" />
            <path id="Vector_20" d="M86.3258 10.4447H87.2026V11.1827H87.274C87.5085 10.6395 88.0591 10.3115 88.6402 10.3627C89.466 10.3012 90.1797 10.9162 90.2409 11.7465C90.2409 11.8592 90.2409 11.972 90.2307 12.0745V15.0572H89.3233V12.3C89.3233 11.562 89.0072 11.193 88.3343 11.193C87.7532 11.1622 87.2638 11.6132 87.2332 12.1975C87.2332 12.2487 87.2332 12.3102 87.2332 12.3615V15.0572H86.3258V10.4447Z" fill="white" />
            <path id="Vector_21" d="M95.3489 9.29675V10.4653H96.348V11.234H95.3489V13.612C95.3489 14.0938 95.5426 14.309 96.0014 14.309C96.1135 14.309 96.2359 14.309 96.348 14.2885V15.047C96.1849 15.0778 96.0218 15.088 95.8587 15.0983C94.8493 15.0983 94.4517 14.7395 94.4517 13.8478V11.2443H93.7278V10.4755H94.4517V9.307H95.3591L95.3489 9.29675Z" fill="white" />
            <path id="Vector_22" d="M97.5714 8.651H98.4686V11.193H98.54C98.7847 10.6498 99.3454 10.3218 99.9368 10.3628C100.773 10.3218 101.487 10.9573 101.527 11.7978C101.527 11.89 101.527 11.9925 101.517 12.0848V15.0675H100.61V12.3103C100.61 11.5723 100.273 11.2033 99.6309 11.2033C99.0396 11.152 98.5196 11.5928 98.4788 12.1873C98.4788 12.2488 98.4788 12.3103 98.4788 12.3718V15.0675H97.5714V8.651Z" fill="white" />
            <path id="Vector_23" d="M106.809 13.817C106.554 14.6882 105.718 15.252 104.821 15.1495C103.668 15.1802 102.71 14.268 102.679 13.1097C102.679 12.997 102.679 12.8842 102.7 12.7715C102.547 11.603 103.363 10.537 104.515 10.3832C104.617 10.373 104.719 10.3627 104.81 10.3627C106.085 10.3627 106.86 11.2442 106.86 12.6895V13.0072H103.617V13.0585C103.556 13.735 104.056 14.3192 104.729 14.3807C104.77 14.3807 104.81 14.3807 104.841 14.3807C105.279 14.432 105.718 14.2167 105.932 13.817H106.809ZM103.628 12.3307H105.942C105.983 11.7157 105.534 11.1827 104.922 11.1417C104.882 11.1417 104.851 11.1417 104.81 11.1417C104.158 11.1417 103.628 11.6542 103.617 12.3102C103.617 12.3102 103.617 12.3307 103.617 12.341L103.628 12.3307Z" fill="white" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_140_1125">
          <rect width="122" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppStore;
