/* eslint-disable react/no-danger */
import { useMemo } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useQRCode as useQrCodeLib } from 'next-qrcode';
import PropTypes from 'prop-types';
import { useStore } from '@/utils/store';
import useQRcode from '@/components/common/Icons/useQRcode';
import DownloadInfoImage from '@/components/pages/home/imgs/downloadInfoImage.png';
import GooglePlay from './svg/GooglePlay';
import AndroidPackage from './svg/AndroidPackage';
import AppStore from './svg/AppStore';
import logo from './svg/120.png';
import IOSApp from './svg/iOSApp';
import styles from './DownloadPage.module.scss';

function DownloadPage({ scanQrCode = false }) {
  const { t, i18n } = useTranslation('pages', { keyPrefix: 'download' });
  const { downloadURL, storageData } = useStore().setting;
  const { url, qrCode } = useQRcode({ locale: i18n.language, ru: storageData?.ru });
  const { Canvas } = useQrCodeLib();

  const downloadHref = useMemo(
    () => ({
      brand: downloadURL?.brand ? downloadURL.brand : {
        googleUrl: 'https://bitop.com/',
        androidUrl: 'https://bitop.com/',
        iosUrl: 'https://bitop.com/',
        superSignatureUrlCn: 'https://bitop.com/',
      },
    }),
    [url, downloadURL],
  );

  return (
    <div className={styles.downloadInfo} id="download">
      <div className={styles.downloadInfoContent}>
        <div className={styles.downloadInfoLeft}>
          <div className={styles.h1} dangerouslySetInnerHTML={{ __html: t('title') }} />
          <div className={styles.downloadInfotitle}>
            <h2 dangerouslySetInnerHTML={{ __html: t('des') }} />
          </div>
          <div className={styles.downloadInfoData}>
            {scanQrCode && (
              <div className={styles.qrBorder}>
                <div className={styles.image}>
                  <Image alt="AppQrcode" src={qrCode} />
                </div>
              </div>
            )}
            <div className={styles.downloadInfoQrcodeText}>
              <div className={styles.downloadInfolink}>
                <div className={styles.androidCon}>
                  <div className={styles.desc}>Android</div>
                  <a target="_blank" aria-label="Google download" href={downloadHref.brand.googleUrl} rel="noreferrer" className={styles.appLink}>
                    {/* {i18n.language === 'en' ? <GooglePlayEn /> : <GooglePlayZh />} */}
                    <GooglePlay />
                    <div className={styles.canvasWrapper}>
                      <Canvas
                        text={downloadHref.brand.googleUrl}
                        logo={logo}
                        options={{
                          errorCorrectionLevel: 'M',
                          margin: 0,
                          scale: 8,
                          width: 100,
                        }}
                      />
                    </div>
                  </a>
                  <a target="_blank" aria-label="Android download" href={downloadHref.brand.androidUrl} rel="noreferrer" className={styles.appLink}>
                    <div className={styles.svgWrapper}>
                      <AndroidPackage />
                      {i18n?.language === 'zh' && <div className={styles.svgWords}>{t('svgWords')}</div>}
                    </div>
                    <div className={styles.canvasWrapper}>
                      <Canvas
                        text={downloadHref.brand.androidUrl}
                        logo={logo}
                        options={{
                          errorCorrectionLevel: 'M',
                          margin: 0,
                          scale: 8,
                          width: 100,
                        }}
                      />
                    </div>
                  </a>
                </div>
                <div className={styles.iOSCon}>
                  <div className={styles.desc}>iOS</div>
                  <a target="_blank" aria-label="AppStore download" href={downloadHref.brand.iosUrl} rel="noreferrer" className={styles.appLink}>
                    {/* {i18n.language === 'en' ? <AppStoreEn /> : <AppStoreZh />} */}
                    <AppStore />
                    <div className={styles.canvasWrapper}>
                      <Canvas
                        text={downloadHref.brand.iosUrl}
                        logo={logo}
                        options={{
                          errorCorrectionLevel: 'M',
                          margin: 0,
                          scale: 8,
                          width: 100,
                        }}
                      />
                    </div>
                  </a>
                  <a target="_blank" aria-label="iOSApp download" href={downloadHref.brand.superSignatureUrlCn} rel="noreferrer" className={styles.appLink}>
                    <div className={styles.svgWrapper}>
                      <IOSApp />
                      {i18n?.language === 'zh' && <div className={styles.svgWords}>{t('svgWords')}</div>}
                    </div>
                  </a>
                </div>
              </div>
              <div className={styles.infoDesc}>
                <div className={styles.symbol}>ⓘ</div>
                <div className={styles.text}>{t('infoDesc')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.downloadInfoImage}>
          <Image alt="downloadInfoImage" src={DownloadInfoImage} objectFit="contain" />
        </div>
      </div>
    </div>
  );
}

DownloadPage.propTypes = {
  scanQrCode: PropTypes.bool,
};
DownloadPage.defaultProps = {
  scanQrCode: false,
};

export default DownloadPage;
